<template>
  <div class="main">
    <NonHeader/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
import NonHeader from "@/components/NonHeader";

export default {
  name: "CommonView",
  components: {NonHeader, Footer}
}
</script>

<style scoped>
.main {
  min-width: 1140px;
  margin: 0 auto;
}
</style>
