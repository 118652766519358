<template>
  <div class="header">
    <img src="@/assets/images/IA/img_nav_logo.png" alt="logo" class="header-logo ">
  </div>
</template>

<script>
export default {
  name: "NonHeader"
}
</script>

<style scoped>
.header {
  background: #FFFFFF;
  height: 80px;
  padding: 0 25px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1140px;
  margin: 0 auto;
}

.header-logo {
  width: 190px;
  height: 48px;
}

.header p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 36px;
  color: #000000;
  margin: 0;
  cursor: pointer;
}

.user-name {
  cursor: default !important;
}

.header strong {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #999999;
  margin: 0;
}

.user-info-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  align-items: center;
}

.divide {
  color: #E1E3E6 !important;
  width: 13px;
  text-align: center;
  margin: 0 10px !important;
}

.level {
  width: 49px;
  height: 24px;
  background: #FFFFFF;
  border: 2px solid #0069CA;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #0069CA;
  text-align: center;
  padding-top: 1px;
  margin-right: 5px;
}

</style>
